html, body {
  background-color: #fff;
  color: #6c6c6f;
  height: 100%;
  width: 100%;
  font-size: 100%;
}

.App {
  padding-top: 75px;
  text-align: center;
}

.centered_content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.footer {
  position: fixed;
  background-color: #6C6C6F;
  color: #C8C8CC;
  bottom: 0;
  left: 0;
  top: auto;
  right: auto;
  height: 64px;
  width: 100%;

  .copyright_notice {
    align-self: center;
    justify-self: center;
  }
}

.headerLogo {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-content: flex-start;
}

.headerLinks {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  
  > a {
    text-decoration: none !important;
    font-weight: bold;
    color: #6c6c6f;
  }
}

.hero-image-container {
  position: relative;
  width: 100%;
  height: 50vh;
  max-height: 450px;
}

.home-hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.photo-credit {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #6c6c6f;
  color: #C8C8CC;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
  a {
    color: #fff;
  }
}

.contentFrame {
  max-width: 900px;
  width: 100%;
  min-width: 200px;
  margin: 2% auto;
  text-align: justify;
}
